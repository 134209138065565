import { Message } from "element-ui";

let msg = {
  succeed: text => {
    Message({
      message: text,
      type: "success"
    });
  },
  done: text => {
    Message({
      message: text,
      type: "success"
    });
  },
  warn: text => {
    Message({
      message: text,
      type: "warning"
    });
  },
  error: text => {
    Message({
      message: text,
      type: "error"
    });
  },
  info: text => {
    Message({
      message: text
    });
  }
};

export default msg;
