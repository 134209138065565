import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getRealPaper = (coursePrefix, paperId, source, year, province) => {
  return ajax.get(
    `${base}/api/itedu/v1/crawler/fb/real-paper?coursePrefix=${coursePrefix}&paperId=${paperId}&source=${source}&year=${year}&province=${province}`
  );
};

const getRealPaperWithUrl = (coursePrefix, paperId, userId, sess) => {
  return ajax.get(
    `${base}/api/itedu/v1/crawler/fb/real-paper-with-url?coursePrefix=${coursePrefix}&paperId=${paperId}&userId=${userId}&sess=${sess}`
  );
};

const search = (subjectCourseId, type, query, year, province, keypointId, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/crawler/fb/question/search?subjectCourseId=${subjectCourseId}&type=${type}&query=${query}&year=${year}&province=${province}&keypointId=${keypointId}&start=${start}&len=${len}`
  );
};
///
const syncup = (coursePrefix, paperId, source, year, province, hasForce) => {
  return ajax.get(
    `${base}/api/itedu/v1/crawler/fb/question/sync?coursePrefix=${coursePrefix}&paperId=${paperId}&source=${source}&year=${year}&province=${province}&hasForce=${hasForce}`
  );
};

const jzInterview = (url, userId, sess) => {
  return ajax.get(`${base}/api/itedu/v1/crawler/fb/jzInterview/question?url=${url}&userId=${userId}&sess=${sess}`);
};

const getShenlunPaper = (paperId, userId, sess) => {
  return ajax.get(`${base}/api/itedu/v1/crawler/fb/shenlun-paper?paperId=${paperId}&userId=${userId}&sess=${sess}`);
};

const getFbPaperMetaList = (coursePrefix, labelIds) => {
  return ajax.get(
    `${base}/api/itedu/v1/crawler/fb/fb-paper-meta-list?coursePrefix=${coursePrefix}&labelIds=${labelIds}`
  );
};

const setFbCookie = (userId, sess) => {
  // /api/itedu/v1/crawler/fb/cookie
  return ajax.post(`${base}/api/itedu/v1/crawler/fb/cookie?userId=${userId}&sess=${sess}`);
};

export const crawlerApi = {
  getRealPaper: getRealPaper,
  getRealPaperWithUrl: getRealPaperWithUrl,
  search: search,
  syncup: syncup,
  jzInterview: jzInterview,
  getShenlunPaper: getShenlunPaper,
  getFbPaperMetaList: getFbPaperMetaList,
  setFbCookie: setFbCookie,
};
