<template>
  <section>
    <el-form label-width="90px" style="min-width:700px">
      <el-form-item label="">
        支持行测，教资科目一、科目二、科目三，教师招聘教综，公基，
      </el-form-item>
      <el-form-item label="coursePrefix">
        <el-input v-model="coursePrefix" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="真题ID">
        <el-input v-model="paperId" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="真题名称">
        <el-input v-model="source" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="年份">
        <el-input v-model="year" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="省份">
        <el-input v-model="province" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="学生/教研">
        <el-select v-model="paperMode" placeholder="请选择">
          <el-option v-for="item in paperModeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="primary" @click="onSyncQuestion">同步到题库</el-button>
      </el-form-item>
    </el-form>
    <div class="paper" v-if="paperMode != 'student'">
      {{ fbPaperSheet.name }} <br />
      <section v-for="(chapter, cIndex) in fbPaperSheet.chapters" :key="cIndex">
        {{ chapter.name }}: {{ chapter.desc }} <br />
        <div class="chapter">
          <div class="question" v-for="(ques, qIndex) in chapter.fbSolutions" :key="qIndex">
            <div class="choice" v-if="ques.type == 1 || ques.type == 5">
              <div v-if="ques.materialList">
                <span v-for="(m, mIdx) in ques.materialList" :key="mIdx">
                  <span v-html="m.content"></span>
                  <br />
                </span>
              </div>
              {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br />
              <span v-for="(opt, optIdx) in ques.accessories[0].options" :key="optIdx">
                {{ warpABCD(optIdx) }}. <span v-html="opt"> </span> <br />
              </span>
              <br />
              {{ ques.questionIndex }}.{{ choiceAnswer(ques) }} <br />
              <span v-html="ques.solution"></span>
            </div>
            <div v-else>
              <div v-if="ques.materialList">
                <span v-for="(m, mIdx) in ques.materialList" :key="mIdx">
                  <span v-html="m.content"></span>
                  <br />
                </span>
              </div>
              {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br /><br />
              {{ ques.questionIndex }}. <span v-html="ques.correctAnswer.answer"> </span> <br />
              <!-- <span v-html="ques.materialSolution.correctAnswer.answer"></span> -->
            </div>
            <div v-if="ques.questionMetaHtml" v-html="ques.questionMetaHtml"></div>
            <div v-if="ques.keypointListHtml" v-html="ques.keypointListHtml"></div>
          </div>
        </div>
        <br />
      </section>
    </div>
    <div class="paper" v-else>
      {{ fbPaperSheet.name }} <br />
      <section v-for="(chapter, cIndex) in fbPaperSheet.chapters" :key="cIndex">
        {{ chapter.name }}: {{ chapter.desc }} <br />
        <div class="chapter">
          <div class="question" v-for="(ques, qIndex) in chapter.fbSolutions" :key="qIndex">
            <div class="choice" v-if="ques.type == 1 || ques.type == 5">
              <div v-if="ques.materialList">
                <span v-for="(m, mIdx) in ques.materialList" :key="mIdx">
                  <span v-html="m.content"></span>
                  <br />
                </span>
              </div>
              {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br />
              <span v-for="(opt, optIdx) in ques.accessories[0].options" :key="optIdx">
                {{ warpABCD(optIdx) }}. <span v-html="opt"> </span> <br />
              </span>
              <br />
            </div>
            <div v-else>
              <div v-if="ques.materialList">
                <span v-for="(m, mIdx) in ques.materialList" :key="mIdx">
                  <span v-html="m.content"></span>
                  <br />
                </span>
              </div>
              {{ ques.questionIndex }}. <span v-html="ques.content"></span> <br /><br />
            </div>
          </div>
        </div>
        <br />
      </section>
      <h1>参考解析</h1>
      <section v-for="(chapter, cIndex) in fbPaperSheet.chapters" :key="'a' + cIndex">
        {{ chapter.name }}: {{ chapter.desc }} <br />
        <div class="chapter">
          <div class="question" v-for="(ques, qIndex) in chapter.fbSolutions" :key="'b' + qIndex">
            <div class="choice" v-if="ques.type == 1 || ques.type == 5">
              {{ ques.questionIndex }}.{{ choiceAnswer(ques) }} <br />
              <span v-html="ques.solution"></span> <br /><br />
            </div>
            <div v-else>{{ ques.questionIndex }}. <span v-html="ques.correctAnswer.answer"> </span> <br /><br /></div>
          </div>
        </div>
        <br />
      </section>
    </div>
  </section>
</template>

<script>
import { crawlerApi } from "@/api/crawlerApi";
import msg from "@/util/msg.js";
export default {
  data() {
    return {
      paperModeOptions: [
        {
          value: "student",
          label: "学生",
        },
        {
          value: "teacher",
          label: "教研",
        },
      ],
      paperMode: "student",
      fbPaperSheet: {},
      fbUnivSolution: {},
      materialDct: {},
      coursePrefix: "jszgzhz",
      paperId: "203544",
      source: "2023年上半年教师资格证考试《综合素质》（中学）题（考生回忆版）",
      force: 0,
      year: "2023",
      province: "国家",
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.force) {
      this.force = this.$route.query.force;
    }
  },
  methods: {
    warpABCD(idx) {
      let text = String.fromCharCode(65 + idx);
      return text;
    },
    choiceAnswer(ques) {
      //return ques.correctAnswer.choice;
      let _this = this;
      let tmpList = ques.correctAnswer.choice.split(",");
      tmpList = tmpList.map((x) => _this.warpABCD(Number(x)));
      let showAns = tmpList.join(",");
      return showAns;
    },
    wrapQuestionMeta(ques) {
      let ans = "";
      if (ques.questionMeta && ques.questionMeta.correctRatio && ques.questionMeta.mostWrongAnswer) {
        ans =
          "正确率：" +
          ques.questionMeta.correctRatio.toFixed(2) +
          "%" +
          "<br/>" +
          "易错项：" +
          this.warpABCD(Number(ques.questionMeta.mostWrongAnswer.choice)) +
          "<br />";
      }
      return ans;
    },
    wrapKeypoint(ques) {
      let ans = "";
      for (let item of ques.keypointList) {
        let tmpList = item.path.map((x) => x.name);
        let tmp = "考点：" + item.kp.name + "，" + "考点父节点：" + tmpList.join("--");
        ans += tmp + "<br/>";
      }
      return ans + "<br />";
    },
    onQuery() {
      if (!this.coursePrefix) {
        msg.warn("coursePrefix 不允许为空");
        return;
      }
      if (!this.isNumeric(this.paperId) || parseInt(this.paperId) <= 0) {
        msg.warn("paperId 必须是大于0的整数");
        return;
      }
      if (!this.source) {
        msg.warn("真题名称 不允许为空");
        return;
      }
      if (!this.province) {
        msg.warn("province 不允许为空");
        return;
      }
      if (!this.isNumeric(this.year) || parseInt(this.year) <= 0) {
        msg.warn("year 必须是大于0的整数");
        return;
      }

      crawlerApi
        .getRealPaper(this.coursePrefix, this.paperId, this.source, this.year, this.province)
        .then((ret) => {
          let { code, data } = ret;
          let usedMaterialId = new Set();
          if (code == 0) {
            console.log("#", ret);
            this.fbPaperSheet = data.fbPaperSheet;
            this.fbUnivSolution = data.fbUnivSolution;
            for (let i = 0; i < this.fbUnivSolution.solutions.length; i++) {
              let solution = this.fbUnivSolution.solutions[i];
              solution.questionIndex = i + 1;
              // 把题目序号 replace进去
              solution.content = solution.content.replaceAll("<p>", "");
              solution.content = solution.content.replaceAll("</p>", "");
              if (solution.solution) {
                solution.solution = solution.solution.replaceAll("<p>", "");
                solution.solution = solution.solution.replaceAll("</p>", "<br />");
              }
              if (solution.correctAnswer.answer) {
                solution.correctAnswer.answer = solution.correctAnswer.answer.replaceAll("<p>", "");
                solution.correctAnswer.answer = solution.correctAnswer.answer.replaceAll("</p>", "<br />");
              }

              solution.questionMetaHtml = this.wrapQuestionMeta(solution);
              solution.keypointListHtml = this.wrapKeypoint(solution);
              if (solution.type == 1 && solution.accessories) {
                // 单选题，option要清除
                let optList = solution.accessories[0].options;
                for (let i = 0; i < optList.length; i++) {
                  optList[i] = optList[i].replaceAll("<p>", "");
                  optList[i] = optList[i].replaceAll("</p>", "");
                }
              }

              //把材料设置到题目里，方便展示
              if (solution.materialIndexes) {
                let materialList = [];
                for (let idx of solution.materialIndexes) {
                  let m = this.fbUnivSolution.materials[idx];
                  if (!usedMaterialId.has(m.id)) {
                    usedMaterialId.add(m.id); // has
                    materialList.push(m);
                  }
                }
                solution.materialList = materialList;
              }
              // 判断对错的题目，做一些设置
              if (solution.type == 5) {
                let options = ["正确", "错误"];
                solution.accessories = [];
                solution.accessories.push({
                  options: options,
                  type: 101,
                });
              }
            }
            for (let chapter of this.fbPaperSheet.chapters) {
              let start = chapter.start;
              let end = chapter.end;
              chapter.fbSolutions = this.fbUnivSolution.solutions.slice(start, end);
            }
            console.log("##", this.fbPaperSheet);
          } else {
            msg.error("出错了:" + ret.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
          msg.error("出错了" + err);
        });
    },
    onSyncQuestion() {
      const hasForce = this.force == 1 ? true : false;
      //subjectCourseId, paperId, userId, sess, force
      crawlerApi
        .syncup(this.coursePrefix, this.paperId, this.source, this.year, this.province, hasForce)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            msg.done("同步成功");
          }
          if (code == 0 && data == false) {
            msg.warn("不允许重复同步");
          }
          if (code != 0) {
            msg.error("出错了" + ret.msg);
            return;
          }
        })
        .catch((err) => {
          msg.error("出错了" + err);
        });
    },
    isNumeric(str) {
      if (typeof str != "string") return false; // we only process strings!
      return (
        !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      ); // ...and ensure strings of whitespace fail
    },
  },

  watch: {},
};
</script>

<style lang="less" scoped>
.paper {
  text-align: left;
}
.solution {
  text-align: left;
}
</style>
