import { render, staticRenderFns } from "./fbRealPaper.vue?vue&type=template&id=62d19e48&scoped=true&"
import script from "./fbRealPaper.vue?vue&type=script&lang=js&"
export * from "./fbRealPaper.vue?vue&type=script&lang=js&"
import style0 from "./fbRealPaper.vue?vue&type=style&index=0&id=62d19e48&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62d19e48",
  null
  
)

export default component.exports